import React, { useState } from "react";
import { pagesAssets } from "./data";
import posthog from "posthog-js";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card } from "./Card";
import useSpeechToText from "./hooks/useSpeechToText2";
import useVoice2Text from "./hooks/useVoice2Text";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

/**
 * TODO:
 * - Change the custom button from play to pause [DONE]
 * - Need to add image to the pages (need to remove the text) [DONE]
 * - Custom Button to be on the bottom of the page [DONE]
 * - Custom Button changes color when completed [DONE]
 *
 * - Main button plays and goes to the next track [DONE]
 * - Swipe right goes to the next page [DONE]
 * - Option to select the next sound manually [DONE]
 *
 * - When track is done it has to vibrate the phone.
 */

const Sound: React.FC<{}> = () => {
  const [counter, setCounter] = useState(0);
  const [wasSwiped, setWasSwiped] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [currentActivePage, setActiveCurrentPage] = useState(0);

  const {
    isListening,
    startListening,
    stopListening,
    transcript,
    resetTranscript,
  } = useSpeechToText({ continuous: true });

  const {
    isListening: listenStatus,
    stopVoice,
    startVoice,
    text,
    isLoading,
    resetText,
    pauseVoice,
  } = useVoice2Text();

  const handleAfterSwipe = (nextSlide: number) => {
    const audio = document.getElementById(
      `audio_tag_page${nextSlide}-${counter}`
    ) as HTMLAudioElement;

    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    setWasSwiped(true);

    setActiveCurrentPage(nextSlide);
    setCounter(0);
    resetText();

    posthog.capture("page swiped", {
      pageSwipedTo: nextSlide,
    });
  };

  const handleOnClickVoice = () => {
    if (!listenStatus) {
      startVoice();
      setIsMuted(false);
    } else {
      stopVoice();
    }
  };

  return (
    <>
      <div>FH: {text}</div>
      <button onClick={handleOnClickVoice}>
        {listenStatus ? "Stop" : "Start"} listening
      </button>
      {/* <button onClick={() => setIsMuted(!isMuted)}>
        {isMuted ? "Unmute" : "Mute"}
      </button> */}
      <div>{isLoading ? "LOADING" : "LOADED"}</div>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        beforeChange={(nextSlide) => handleAfterSwipe(nextSlide)}
      >
        {pagesAssets.map(({ passage, sounds, imageSrc }, index) => {
          return (
            <Card
              key={index}
              passage={passage}
              isListening={listenStatus}
              currentActivePage={currentActivePage}
              transcript={text}
              isMuted={isMuted}
              resetTranscript={resetText}
              pageSounds={sounds}
              imageSrc={imageSrc}
              pageNumber={index}
              wasSwiped={wasSwiped}
              setWasSwiped={setWasSwiped}
              counter={counter}
              setCounter={setCounter}
            />
          );
        })}
      </Carousel>
    </>
  );
};

export default Sound;

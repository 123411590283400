import React, { useState } from "react";
import { PassageProperties } from "../data";

const useParsePassage = (passage: PassageProperties) => {
  const [arrayPassage] = useState(
    passage.words
      .toLocaleLowerCase()
      .replace(RegExp(/[^a-zA-Z\d\s:]/g), "")
      .split(" ")
  );
  const [activeWordIndex, setActiveWordIndex] = useState(0);

  const didItMatch = (
    transcript: string,
    playAudio: () => void,
    indexPlaying: number
  ) => {
    //Removes all the caps and non-alphanumerics
    const normalizedTranscript = transcript
      .toLocaleLowerCase()
      .replace(RegExp(/[^a-zA-Z\d\s:]/g), "");
    const normalizedTranscriptArray = normalizedTranscript.split(" ");

    let matched = false;
    const rangeLimit = 4;
    const acceptableHitsWithinRange = 1;
    let hits = 0;
    for (
      let range = activeWordIndex;
      range < activeWordIndex + rangeLimit;
      range++
    ) {
      if (!arrayPassage[range]) break;

      if (
        normalizedTranscriptArray.findIndex(
          (word, index) => word[0] === arrayPassage[range][0]
        ) > -1
      ) {
        hits++;
      }

      if (hits >= acceptableHitsWithinRange) {
        matched = true;
        break;
      }
    }

    //Play sound if matched
    if (
      matched &&
      !!passage.audio &&
      indexPlaying === -1 &&
      arrayPassage[activeWordIndex] === passage.audio
    ) {
      playAudio();
    }

    // Move to the next word
    if (matched && arrayPassage.length !== activeWordIndex + 1)
      setActiveWordIndex(activeWordIndex + 1);

    // If last word, swipe.

    return matched;
  };

  return {
    didItMatch,
    arrayPassage,
    activeWordIndex,
    setActiveWordIndex,
  };
};

export default useParsePassage;

export type PassageProperties = {
  words: string;
  audio?: string;
};

type PageAsset = {
  imageSrc: string;
  sounds: string[];
  passage: PassageProperties;
};

export const pagesAssets: PageAsset[] = [];
for (let slide = 1; slide < 36; slide++) {
  let sounds = [];
  let passage = { words: "" };
  try {
    const sound = require(`./assets/slides/${slide}/${slide}.mp3`);
    sounds.push(sound);
  } catch (e) {
    console.error(e);
  }
  try {
    passage = require(`./assets/slides/${slide}/${slide}.json`);
  } catch (e) {
    console.error(e);
  }
  pagesAssets.push({
    imageSrc: require(`./assets/slides/${slide}/${slide}.jpg`),
    passage,
    sounds,
  });
}
